import { ChainId } from '@uniswap/sdk-core'
import { CHAIN_SUBGRAPH_URL } from 'graphql/thegraph/apollo'
import { useQuery } from 'react-query'

interface AlltimeResponse {
  total_vols: number
  vols_24h: number
}

interface FeeResponse {
  data: {
    factories: {
      totalFeesUSD: string
    }[]
  }
}

async function fetcher() {
  const res = await fetch('https://stats-api.hold.so/v1/volumes')
  const res1 = await fetch('https://stats-api.hold.so/bera/v1/volumes')

  const all = await Promise.all([res.json(), res1.json()])

  return all
}

export function useAlltime() {
  const data = useQuery<[AlltimeResponse, AlltimeResponse]>(['query-alltimes'], () => fetcher(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}

const url = CHAIN_SUBGRAPH_URL[ChainId.ZKSYNC]
const url_bera = CHAIN_SUBGRAPH_URL[ChainId.BERACHAIN]

export function useFees() {
  const data = useQuery<[FeeResponse, FeeResponse]>(
    ['query-fees'],
    async () => {
      const res = await fetch(url_bera, {
        method: 'POST',
        body: JSON.stringify({
          query: 'query uniswapFactories {\n  factories(first: 1, subgraphError: allow) {\n    totalFeesUSD\n  }\n}',
          operationName: 'uniswapFactories',
          extensions: {},
        }),
      })

      const res1 = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          query:
            'query uniswapFactories {\n  factories(block: {number: 55975903}, first: 1, subgraphError: allow) {\n    totalFeesUSD\n  }\n}',
          operationName: 'uniswapFactories',
          extensions: {},
        }),
      })

      const data = await Promise.all([res.json(), res1.json()])

      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: 60000,
    }
  )

  return data
}

async function fetcherSwapper() {
  const res = await fetch('https://gateway.holdstation.com/services/blockchain/api/hold-so/all-time-swappers')

  return res.json()
}

export function useSwapper() {
  const data = useQuery<{ data: string }>(['query-swappers'], () => fetcherSwapper(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}

interface HighlightResponse {
  name: string
  symbol: string
  market_data: {
    current_price: {
      usd: string
    }
  }
}

export function useHighlight() {
  const data = useQuery<HighlightResponse[]>(
    ['query-highlight'],
    async () => {
      const res = await fetch('https://api.holdstation.com/api/coins/hold-so-highlight')

      return res.json()
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: 60000,
    }
  )

  return data
}
