import bg2 from 'assets/png/bg2.png'
import halftone from 'assets/png/halftone.png'
import right from 'assets/png/right.png'
import bg1 from 'assets/svg/bg1.svg'
import bg3 from 'assets/svg/bg3.svg'
import hero from 'assets/video/hero.webm'

export function Hero() {
  return (
    <div className="absolute right-0 left-0 top-0">
      <video playsInline autoPlay loop muted className="w-full object-cover h-[447px] lg:h-[60vh] 2xl:h-[50vh]">
        <source src={hero} type="video/webm" />
      </video>

      <div className="flex items-center justify-between overflow-hidden radio-background min-h-[120px] lg:h-[calc(40vh-72px)] 2xl:h-[calc(50vh-72px)]">
        <div className="flex items-center">
          <img src={bg2} alt={bg2} className="w-[63px] lg:w-[114px]" />
          <img src={bg1} alt={bg1} className="w-[42px] lg:w-[77px] -translate-y-full" />

          <img src={halftone} alt={halftone} className="-rotate-[50deg] w-[56px] lg:w-auto" />
        </div>

        <div className="flex items-center lg:gap-[110px]">
          <img src={bg3} alt={bg3} className="-rotate-[135deg] w-[56px] lg:w-auto" />

          <img src={right} alt={right} className="w-[56px] lg:w-[210px]" />
        </div>
      </div>
    </div>
  )
}
