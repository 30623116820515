import { useWeb3React } from '@web3-react/core'
import Zksync from 'assets/png/Zksync.png'
import bera from 'assets/png/bera.png'
import history from 'assets/svg/history.svg'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async/lib/index'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { ModalAa } from './aa'
import { Balance } from './balance'
import { Button } from './button'
import { Chain } from './chain'
import { BERA_OFT, ZKSYNC_OFT } from './constant'
import { EstimateReceive } from './estimate'
import { useFee } from './hook/useFeeBridge'

export interface BrigeForm {
  active: number
  value: string
  from: {
    chainId: number
    name: string
    img: string
  }
  to: {
    name: string
    chainId: number
    img: string
  }
}

export function Bridge() {
  const { data } = useFee()
  // const { chainId } = useWeb3React()
  const { from, to } = useMemo(() => {
    return {
      from: {
        chainId: ZKSYNC_OFT.chainId,
        name: ZKSYNC_OFT.name,
        img: Zksync,
      },
      to: {
        chainId: BERA_OFT.chainId,
        name: BERA_OFT.name,
        img: bera,
      },
    }

    // if (chainId === ZKSYNC_OFT.chainId) {
    //   return {
    //     from: {
    //       chainId: ZKSYNC_OFT.chainId,
    //       name: ZKSYNC_OFT.name,
    //       img: Zksync,
    //     },
    //     to: {
    //       chainId: BERA_OFT.chainId,
    //       name: BERA_OFT.name,
    //       img: bera,
    //     },
    //   }
    // }
    // return {
    //   from: {
    //     chainId: BERA_OFT.chainId,
    //     name: BERA_OFT.name,
    //     img: bera,
    //   },
    //   to: {
    //     chainId: ZKSYNC_OFT.chainId,
    //     name: ZKSYNC_OFT.name,
    //     img: Zksync,
    //   },
    // }
  }, [])
  const methods = useForm<BrigeForm>({
    defaultValues: {
      active: 0,
      value: '',
      from,
      to,
    },
    mode: 'all',
  })
  const { account } = useWeb3React()

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>Holdstation | Bridge</title>
      </Helmet>

      <div
        className={clsx(
          'w-auto lg:w-full max-w-[502px] relative m-4 p-4 lg:p-5 bg-[#0d0d0d] rounded-2xl border border-[#1f1f1f]',
          'flex flex-col gap-4 overflow-hidden lg:my-10'
        )}
      >
        <div className="flex justify-between items-center">
          <h2 className="text-white text-2xl font-medium">Bridge</h2>

          <Link
            to={`https://explorer.zksync.io/address/${account}`}
            target="_blank"
            className={clsx('flex justify-center items-center gap-1 px-2 py-1 bg-[#1f1f1f] rounded-full', {
              'opacity-0 pointer-events-none invisible': !account,
            })}
          >
            <div className="w-5 h-5 relative">
              <img src={history} alt="history" width={20} height={20} loading="lazy" className="w-full h-full" />
            </div>

            <p className="text-white text-sm">History</p>
          </Link>
        </div>

        <div className="flex flex-col gap-3">
          <Chain />

          <Balance />
        </div>

        <div className="w-full h-[1px] bg-[#191919]" />

        <EstimateReceive fee={data ?? 0} />

        <Button fee={data ?? 0} />
      </div>

      <ModalAa />
    </FormProvider>
  )
}
