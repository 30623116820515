import berachain from 'assets/png/berachain.png'
import defi from 'assets/png/defi.webp'
import holdstation3d from 'assets/png/holdstation3d.png'
import lq from 'assets/png/lp.webp'
import zkSync from 'assets/svg/zkSync.svg'
import { Link } from 'react-router-dom'
import { Slider } from './slider'

export function DefiApp() {
  return (
    <section className="relative mx-[16px] xl:mx-[182px] py-20 flex flex-col gap-10 lg:gap-20">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
        <h3 className="text-[28px] lg:text-[48px] leading-[36px] lg:leading-[52px] font-medium lg:mb-10">
          Where the Liquidity Flows
        </h3>

        <div className="flex flex-col gap-5 lg:gap-6">
          <p className="text-base text-[#808080]">
            Hold.so aggregates all major DEXs on Berachain & ZKsync, making swaps effortless and liquidity always
            available for Beras.
          </p>

          <div className="flex items-center justify-between">
            <p className="text-sm">Supported Chains</p>

            <div className="h-[16px] flex items-center justify-center gap-2.5">
              <img src={berachain} alt="berachain" width={239} height={40} loading="lazy" className="w-[107px]" />
              <img src={zkSync} alt="zkSync" width={103} height={20} loading="lazy" className="w-[85px]" />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="border border-dashed border-[#1F1F1F] p-6 flex flex-col gap-6">
          <h2 className="text-base lg:text-2xl">The Liquidity Hub on Berachain and ZKsync</h2>

          <Slider />
        </div>

        <div
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="border border-dashed border-[#1F1F1F] p-6 flex flex-col justify-between gap-6 cursor-pointer"
        >
          <h2 className="text-base lg:text-2xl">One Click, Best Trade</h2>

          <div className="h-[160px] lg:h-[328px] border border-[#1F1F1F]">
            <img src={holdstation3d} alt="defi" className="w-full h-full object-contain" />
          </div>
        </div>

        <div className="border border-dashed border-[#1F1F1F] p-6 flex flex-col gap-6">
          <h2 className="text-base lg:text-2xl">Built Open, Build for the Beras</h2>

          <div className="h-[160px] lg:h-[328px] border border-[#1F1F1F]">
            <img src={defi} alt="defi" className="w-full h-full object-contain" />
          </div>
        </div>

        <Link to="/pool" className="border border-dashed border-[#1F1F1F] p-6 flex flex-col gap-6">
          <h2 className="text-base lg:text-2xl">Be The Liquidity, Earn The Fees</h2>

          <div className="h-[160px] lg:h-[328px] border border-[#1F1F1F]">
            <img src={lq} alt="lq" className="w-full h-full object-contain" />
          </div>
        </Link>
      </div>
    </section>
  )
}
