import clsx from 'clsx'
import { formatDisplay } from 'utils/formatNumbers'
import { useHighlight } from './data'

export function Slider() {
  const { data } = useHighlight()

  if (!data) {
    return (
      <div className="h-[160px] lg:h-[328px] border border-[#1F1F1F] flex items-center justify-center overflow-hidden" />
    )
  }

  return (
    <div className="h-[160px] lg:h-[328px] border border-[#1F1F1F] flex items-center overflow-hidden">
      {[1, 2].map((item, idx) => {
        return (
          <div key={idx} className="flex items-center gap-2.5 marquee__content">
            {data.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={clsx(
                    'flex-shrink-0 highlight border border-[#141414] rounded-full w-[131px] lg:w-[195px] h-[131px] lg:h-[195px]',
                    'flex flex-col items-center justify-center'
                  )}
                >
                  <div className="flex items-center gap-2">
                    <p className="text-sm lg:text-[20px] lg:leading-[27px] opacity-50 text-white uppercase">
                      {item.symbol}
                    </p>
                  </div>

                  <p className="text-2xl lg:text-[34px] lg:leading-[45px] text-white font-medium">{`$${formatDisplay(
                    Number(item.market_data.current_price.usd),
                    { decimalToShow: 2 }
                  )}`}</p>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
