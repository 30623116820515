import { Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, SwapEventName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { StyledNumericalInput } from 'components/NumericalInput'
import PrefetchBalancesWrapper from 'components/PrefetchBalancesWrapper/PrefetchBalancesWrapper'
import Tooltip from 'components/Tooltip'
import { isSupportedChain } from 'constants/chains'
import ms from 'ms'
import { darken } from 'polished'
import { ReactNode, forwardRef, useCallback, useEffect, useState } from 'react'
import { Lock } from 'react-feather'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme/components'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'
import { NumberType, useFormatter } from 'utils/formatNumbers'

import clsx from 'clsx'
import { CurrencySearchFilters } from 'components/SearchModal/CurrencySearch'
import { Text } from 'ui/src'
import { ReactComponent as DropDown } from '../../assets/images/dropdown_v2.svg'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { ButtonGray } from '../Button'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { FiatValue } from './FiatValue'
import { SelectToken } from './SelecToken'
import { formatCurrencySymbol } from './utils'

export const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${flexColumnNoWrap};
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div<{ hideInput: boolean }>`
  min-height: 44px;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
`

interface CurrencySelectProps {
  visible: boolean
  selected: boolean
  hideInput?: boolean
  disabled?: boolean
  animateShake?: boolean
}

export const CurrencySelect = styled(ButtonGray)<CurrencySelectProps>`
  align-items: center;
  background-color: ${({ selected, theme }) => (selected ? theme.surface1 : theme.accent1)};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  color: ${({ selected, theme }) => (selected ? theme.neutral1 : theme.neutralContrast)};
  cursor: pointer;
  height: 36px;
  border-radius: 18px;
  outline: none;
  user-select: none;
  border: 1px solid ${({ selected, theme }) => (selected ? theme.surface3 : theme.accent1)};
  font-size: 24px;
  font-weight: 485;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  padding: ${({ selected }) => (selected ? '4px 8px 4px 4px' : '6px 6px 6px 8px')};
  gap: 8px;
  justify-content: space-between;
  margin-left: ${({ hideInput }) => (hideInput ? '0' : '12px')};
  box-shadow: ${({ theme }) => theme.deprecated_shallowShadow};

  &:hover,
  &:active {
    background-color: ${({ theme, selected }) => (selected ? theme.surface2 : theme.accent1)};
  }

  &:before {
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    content: '';
  }

  &:hover:before {
    background-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
  }

  &:active:before {
    background-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
  }

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  @keyframes horizontal-shaking {
    0% {
      transform: translateX(0);
      animation-timing-function: ease-in-out;
    }
    20% {
      transform: translateX(10px);
      animation-timing-function: ease-in-out;
    }
    40% {
      transform: translateX(-10px);
      animation-timing-function: ease-in-out;
    }
    60% {
      transform: translateX(10px);
      animation-timing-function: ease-in-out;
    }
    80% {
      transform: translateX(-10px);
      animation-timing-function: ease-in-out;
    }
    100% {
      transform: translateX(0);
      animation-timing-function: ease-in-out;
    }
  }
  animation: ${({ animateShake }) => (animateShake ? 'horizontal-shaking 300ms' : 'none')};
`

const InputRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`

const LabelRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  color: ${({ theme }) => theme.neutral2};
  font-size: 0.75rem;
  line-height: 1rem;

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.neutral2)};
  }
`

const FiatRow = styled(LabelRow)`
  justify-content: flex-end;
  min-height: 24px;
  padding: 8px 0px 0px 0px;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  path {
    fill: ${({ selected, theme }) => (selected ? theme.neutral1 : theme.black)};
  }
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size: 20px;
  font-weight: 535;
`

interface SwapCurrencyInputPanelProps {
  value: string
  onUserInput?: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: { data?: number; isLoading: boolean }
  priceImpact?: Percent
  id: string
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  loading?: boolean
  disabled?: boolean
  currencySearchFilters?: CurrencySearchFilters
  numericalInputSettings?: {
    disabled?: boolean
    onDisabledClick?: () => void
    disabledTooltipBody?: ReactNode
  }
  isOutput?: boolean
}

const SwapCurrencyInputPanel = forwardRef<HTMLInputElement, SwapCurrencyInputPanelProps>(
  (
    {
      value,
      onUserInput,
      onMax,
      showMaxButton,
      onCurrencySelect,
      currency,
      otherCurrency,
      id,
      renderBalance,
      fiatValue,
      priceImpact,
      hideBalance = false,
      pair = null, // used for double token logo
      hideInput = false,
      locked = false,
      loading = false,
      disabled = false,
      currencySearchFilters,
      numericalInputSettings,
      label,
      isOutput,
      ...rest
    },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { account, chainId } = useWeb3React()
    const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
    const theme = useTheme()
    const { formatCurrencyAmount } = useFormatter()

    const handleDismissSearch = useCallback(() => {
      setModalOpen(false)
    }, [setModalOpen])

    const [tooltipVisible, setTooltipVisible] = useState(false)
    const handleDisabledNumericalInputClick = useCallback(() => {
      if (numericalInputSettings?.disabled && !tooltipVisible) {
        setTooltipVisible(true)
        setTimeout(() => setTooltipVisible(false), ms('4s')) // reset shake animation state after 4s
        numericalInputSettings.onDisabledClick?.()
      }
    }, [tooltipVisible, numericalInputSettings])

    const chainAllowed = isSupportedChain(chainId)

    // reset tooltip state when currency changes
    useEffect(() => setTooltipVisible(false), [currency])

    return (
      <InputPanel id={id} hideInput={hideInput} {...rest}>
        {locked && (
          <FixedContainer>
            <AutoColumn gap="sm" justify="center">
              <Lock />
              <Text variant="body2" textAlign="center" px="$spacing12">
                <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
              </Text>
            </AutoColumn>
          </FixedContainer>
        )}

        <Container hideInput={hideInput}>
          <div className="flex items-center justify-between min-h-[26px]">
            <p className="text-[14px] leading-[20px] tracking-[-0.28px] text-[#666666]">{label}</p>

            {showMaxButton && selectedCurrencyBalance && (
              <div className="flex items-center gap-[8px]">
                {/* <button className="text-[11px] leading-[12px] text-[#4C4C4C] border border-[#171717] rounded-full px-[12px] py-[8px]">
                  HALF
                </button> */}

                <TraceEvent
                  events={[BrowserEvent.onClick]}
                  name={SwapEventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}
                  element={InterfaceElementName.MAX_TOKEN_AMOUNT_BUTTON}
                >
                  <button
                    onClick={onMax}
                    className="text-[11px] leading-[12px] text-[#4C4C4C] border border-[#171717] rounded-full px-[12px] py-[6px]"
                  >
                    MAX
                  </button>
                </TraceEvent>
              </div>
            )}
          </div>

          <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}>
            {!hideInput && (
              <div style={{ display: 'flex', flexGrow: 1 }} onClick={handleDisabledNumericalInputClick}>
                <StyledNumericalInput
                  className="token-amount-input"
                  value={value}
                  onUserInput={(input) => onUserInput && onUserInput(input)}
                  disabled={!chainAllowed || disabled || numericalInputSettings?.disabled}
                  $loading={loading}
                  id={id}
                  ref={ref}
                  maxDecimals={currency?.decimals}
                />
              </div>
            )}

            <div className="flex items-center gap-2">
              {isOutput && !currency && <SelectToken onCurrencySelect={onCurrencySelect} />}
              <PrefetchBalancesWrapper shouldFetchOnAccountUpdate={modalOpen}>
                <Tooltip
                  show={tooltipVisible && !modalOpen}
                  placement="bottom"
                  offsetY={14}
                  text={numericalInputSettings?.disabledTooltipBody}
                >
                  <button
                    onClick={() => {
                      if (onCurrencySelect) {
                        setModalOpen(true)
                      }
                    }}
                    className={clsx('py-[8px] px-[12px] rounded-full', {
                      'bg-[#1C1C1C]': currency,
                      'bg-white': !currency,
                    })}
                  >
                    <div className="flex items-center gap-[4px]">
                      <div className="flex items-center gap-[8px]">
                        {pair ? (
                          <span>
                            <DoubleCurrencyLogo
                              currency0={pair.token0}
                              currency1={pair.token1}
                              size={24}
                              margin={true}
                            />
                          </span>
                        ) : currency ? (
                          <CurrencyLogo currency={currency} size="28px" />
                        ) : null}
                        {pair ? (
                          <StyledTokenName className="pair-name-container">
                            {pair?.token0.symbol}:{pair?.token1.symbol}
                          </StyledTokenName>
                        ) : (
                          <span
                            className={clsx('text-[20px] leading-[28px] tracking-[-0.32px]', {
                              'text-black': !(currency && currency.symbol),
                              'text-white': Boolean(currency && currency.symbol),
                            })}
                          >
                            {currency ? formatCurrencySymbol(currency) : <Trans>Select token</Trans>}
                          </span>
                        )}
                      </div>

                      {onCurrencySelect && <StyledDropDown selected={!!currency} />}
                    </div>
                  </button>
                </Tooltip>
              </PrefetchBalancesWrapper>
            </div>
          </InputRow>
          {Boolean(!hideInput && !hideBalance) && (
            <FiatRow>
              <RowBetween>
                <LoadingOpacityContainer $loading={loading}>
                  {fiatValue && (
                    <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} testId={`fiat-value-${id}`} />
                  )}
                </LoadingOpacityContainer>
                {account ? (
                  <RowFixed style={{ height: '20px' }}>
                    <ThemedText.DeprecatedBody
                      data-testid="balance-text"
                      color={theme.neutral2}
                      fontWeight={400}
                      fontSize={14}
                      lineHeight="20px"
                    >
                      {!hideBalance && currency && selectedCurrencyBalance ? (
                        renderBalance ? (
                          renderBalance(selectedCurrencyBalance)
                        ) : (
                          <Trans>
                            Balance:{' '}
                            {formatCurrencyAmount({
                              amount: selectedCurrencyBalance,
                              type: NumberType.TokenNonTx,
                            })}{' '}
                            {currency.symbol}
                          </Trans>
                        )
                      ) : null}
                    </ThemedText.DeprecatedBody>
                  </RowFixed>
                ) : (
                  <span className="h-[20px]" />
                )}
              </RowBetween>
            </FiatRow>
          )}
        </Container>
        {onCurrencySelect && (
          <CurrencySearchModal
            isOpen={modalOpen}
            onDismiss={handleDismissSearch}
            onCurrencySelect={onCurrencySelect}
            selectedCurrency={currency}
            otherSelectedCurrency={otherCurrency}
            currencySearchFilters={currencySearchFilters}
          />
        )}
      </InputPanel>
    )
  }
)
SwapCurrencyInputPanel.displayName = 'SwapCurrencyInputPanel'

export default SwapCurrencyInputPanel
