import holdlogo from 'assets/svg/holdstation.svg'
import { Link, NavLink } from 'react-router-dom'

const data = [
  { title: 'Swap ', path: 'swap' },
  { title: 'Pool', path: 'pool' },
  { title: 'Bridge ', path: 'bridge' },
]

export function Footer() {
  return (
    <footer className="flex flex-col gap-5 lg:gap-20 mx-4 xl:mx-[182px] py-[60px]">
      <div className="flex flex-col lg:flex-row lg:items-end justify-between">
        <div className="flex flex-col gap-10 lg:gap-[60px] mb-4 lg:mb-0">
          <NavLink to="/swap" className="w-[96px] aspect-[96/40] inline-block">
            <img src={holdlogo} alt="web icon" className="w-full h-full" loading="lazy" width={97} height={40} />
          </NavLink>

          <p className="text-[28px] lg:text-[32px] leading-[36px] lg:leading-[48px] text-[#808080]">
            Where The <span className="text-white">Liquidity Flows</span>
          </p>
        </div>

        <ul className="flex flex-col lg:flex-row lg:items-center gap-2.5 lg:gap-6">
          {data.map((item, idx) => {
            return (
              <li key={idx} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <Link to={item.path} className="text-base lg:text-xl text-white">
                  {item.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>

      <div className="lg:hidden w-full h-[1px] bg-white/10 mb-[40px]" />

      <div className="flex flex-col-reverse lg:flex-row gap-3 lg:items-center justify-between">
        <p className="text-sm text-[#808080]">© 2025 Holdstation. All rights reserved.</p>

        <div className="flex items-center lg:justify-center gap-2.5">
          <Link className="text-sm" to="https://x.com/HoldstationW" target="_blank">
            X (formerly Twitter)
          </Link>

          <Link className="text-sm" to="https://discord.com/invite/holdstation" target="_blank">
            Discord
          </Link>

          <Link className="text-sm" to="https://t.me/HoldstationW_EN" target="_blank">
            Telegram
          </Link>
        </div>
      </div>
    </footer>
  )
}
