import { Currency } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import { Pool } from '@uniswap/v3-sdk/src/entities'
import syncimage from 'assets/svg/synergy.svg'
import BigNumber from 'bignumber.js'
import { useAllPoolId } from 'pages/Pool/useQueryAllPool'
import { formatDisplay } from 'pages/Swap/Chart/format-balance'
import { NATIVE_ADDRESS } from 'pages/Swap/SwapEstimate/SwapEstimate'
import { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Field } from 'state/mint/v3/actions'
import { useEstimate } from './estimated'

interface EstimatedProps {
  currencies: {
    CURRENCY_A?: Currency | undefined
    CURRENCY_B?: Currency | undefined
  }
  formattedAmounts: {
    [x: string]: string
  }
  feeAmount?: FeeAmount
}

export const WETH = '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91'

function EstimatedPointComponent({ formattedAmounts, currencies, feeAmount }: EstimatedProps) {
  const { data: allPool } = useAllPoolId()
  const { tokenId } = useParams<{ tokenId?: string }>()

  const currencyA = currencies[Field.CURRENCY_A]
  const currencyB = currencies[Field.CURRENCY_B]

  const callData = useMemo(() => {
    const rawAmountA = formattedAmounts[Field.CURRENCY_A]
    const rawAmountB = formattedAmounts[Field.CURRENCY_B]

    let token0 = ''
    let decimal0 = 18

    let token1 = ''
    let decimal1 = 18

    if (currencyA?.isNative) {
      token0 = NATIVE_ADDRESS
      decimal0 = 18
    } else {
      token0 = currencyA?.address || ''
      decimal0 = currencyA?.decimals || 18
    }

    if (currencyB?.isNative) {
      token1 = NATIVE_ADDRESS
      decimal1 = 18
    } else {
      token1 = currencyB?.address || ''
      decimal1 = currencyB?.decimals || 18
    }

    const amount0 = new BigNumber(rawAmountA || 0).multipliedBy(Math.pow(10, decimal0)).toFixed()
    const amount1 = new BigNumber(rawAmountB || 0).multipliedBy(Math.pow(10, decimal1)).toFixed()

    return {
      pool: tokenId || '',
      token0: token0,
      amount0: amount0,
      token1: token1,
      amount1: amount1,
    }
  }, [currencyB, currencyA, currencies, formattedAmounts, allPool, tokenId])

  const [tokenA, tokenB] = useMemo(() => [currencyA?.wrapped, currencyB?.wrapped], [currencyA, currencyB])

  const poolAddress = useMemo(() => {
    if (tokenA && tokenB && feeAmount) {
      return Pool.getAddress(tokenA, tokenB, feeAmount)
    }
    return ''
  }, [tokenA, tokenB, feeAmount])

  const { data } = useEstimate(callData, tokenId || poolAddress)

  return (
    <div className="flex items-center justify-between px-[20px] py-[16px] rounded-[16px] border border-[#121212] mb-5">
      <p className="text-sm text-[#808080]">Estimated Point Earned</p>

      <div className="flex items-center gap-1">
        <p className="text-base text-white">{formatDisplay(Number(data?.estimate || 0), { decimalToShow: 0 })}</p>

        <img src={syncimage} alt="Synergy" width={28} height={28} loading="lazy" className="w-5 h-5" />
      </div>
    </div>
  )
}

export const EstimatedPoint = memo(EstimatedPointComponent)
