// import { CustomUserProperties, getBrowser, SharedEventName } from '@uniswap/analytics-events'
// import { useWeb3React } from '@web3-react/core'
// import { sendAnalyticsEvent, sendInitializationEvent, user } from 'analytics'
import ErrorBoundary from 'components/ErrorBoundary'
import Loader from 'components/Icons/LoadingSpinner'
import { PageTabs } from 'components/NavBar'
import { useFeatureFlagURLOverrides } from 'featureFlags'
import { useAtom } from 'jotai'
const Pool = lazy(() => import('pages/Pool'))

import { lazy, memo, Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async/lib/index'
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom'
import { shouldDisableNFTRoutesAtom } from 'state/application/atoms'
// import { useRouterPreference } from 'state/user/hooks'
// import { StatsigProvider, StatsigUser } from 'statsig-react'
// import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { isPathBlocked } from 'utils/blockedPaths'
import { MICROSITE_LINK } from 'utils/openDownloadApp'
// import { getCLS, getFCP, getFID, getLCP, Metric } from 'web-vitals'

import { Footer } from 'components/footer'
import 'react-loading-skeleton/dist/skeleton.css'
import { AllPoolDetail } from './AllPoolDetail'
import { Bridge } from './bridge'
import { Header } from './Header'
import { AllPool } from './Pool/all-pool'
import { PoolV3 } from './Pool/PoolV3'
import { findRouteByPath, RouteDefinition, routes, useRouterConfig } from './RouteDefinitions'

const AppChrome = lazy(() => import('./AppChrome'))

export default function App() {
  const [, setShouldDisableNFTRoutes] = useAtom(shouldDisableNFTRoutesAtom)

  const location = useLocation()
  const { pathname } = location
  // const currentPage = getCurrentPageFromLocation(pathname)
  // const renderUkBanner = useRenderUkBanner()

  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.get('disableNFTs') === 'true') {
      setShouldDisableNFTRoutes(true)
    } else if (searchParams.get('disableNFTs') === 'false') {
      setShouldDisableNFTRoutes(false)
    }
  }, [searchParams, setShouldDisableNFTRoutes])

  useFeatureFlagURLOverrides()

  // const { account } = useWeb3React()
  // const statsigUser: StatsigUser = useMemo(
  //   () => ({
  //     userID: getDeviceId(),
  //     customIDs: { address: account ?? '' },
  //   }),
  //   [account]
  // )

  // redirect address to landing pages until implemented
  const shouldRedirectToAppInstall = pathname?.startsWith('/address/')
  useLayoutEffect(() => {
    if (shouldRedirectToAppInstall) {
      window.location.href = MICROSITE_LINK
    }
  }, [shouldRedirectToAppInstall])

  if (shouldRedirectToAppInstall) {
    return null
  }

  const shouldBlockPath = isPathBlocked(pathname)
  if (shouldBlockPath && pathname !== '/swap') {
    return <Navigate to="/swap" replace />
  }
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <ErrorBoundary>
        {/* <DarkModeQueryParamReader /> */}
        {/* <Background /> */}

        <Helmet>
          <title>{findRouteByPath(pathname)?.getTitle(pathname) ?? 'Holdstation | Swap'}</title>
        </Helmet>

        {/* {renderUkBanner && <UkBanner />} */}
        <Header />
        <ResetPageScrollEffect />

        <Body />

        <Footer />
      </ErrorBoundary>
    </div>
  )
}

const Body = memo(function Body() {
  const routerConfig = useRouterConfig()
  // const renderUkBanner = useRenderUkBanner()

  return (
    // <BodyWrapper bannerIsVisible={renderUkBanner}>
    <main className="flex-1 relative flex flex-col items-center">
      <Suspense>
        <AppChrome />
      </Suspense>

      <div className="md:hidden mb-[24px] z-[100]">
        <PageTabs />
      </div>

      <Suspense fallback={<Loader />}>
        <Routes>
          {routes.map((route: RouteDefinition) => {
            if (!route.enabled(routerConfig)) {
              return null
            }

            return (
              <Route key={route.path} path={route.path} element={route.getElement(routerConfig)}>
                {route.nestedPaths.map((nestedPath, idx) => (
                  <Route
                    path={nestedPath}
                    element={route.getElement(routerConfig)}
                    key={`${route.path}/${nestedPath}-${idx}`}
                  />
                ))}
              </Route>
            )
          })}

          <Route path="/pool" element={<PoolV3 />}>
            <Route index element={<AllPool />} />
            <Route path="my-pool" element={<Pool />} />
          </Route>

          <Route path="/detail/:id" element={<AllPoolDetail />} />

          <Route path="/bridge" element={<Bridge />} />
        </Routes>
      </Suspense>
    </main>

    // </BodyWrapper>
  )
})

const ResetPageScrollEffect = memo(function ResetPageScrollEffect() {
  const location = useLocation()
  const { pathname } = location
  // const currentPage = getCurrentPageFromLocation(pathname)
  const [hasChangedOnce, setHasChangedOnce] = useState(false)

  useEffect(() => {
    if (!hasChangedOnce) {
      // avoid setting scroll to top on initial load
      setHasChangedOnce(true)
    } else {
      // URL may change without page changing (e.g. when switching chains), and we only want to reset scroll to top if the page changes
      window.scrollTo(0, 0)
    }
    // we don't want this to re-run on change of hasChangedOnce! or else it defeats the point of the fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return null
})
