import { NavLink } from 'react-router-dom'
import holdlogo from '../../assets/images/holdstation.png'

export function HoldstationIcon() {
  return (
    <NavLink
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      to="/swap"
      className="w-[73px] aspect-[73/36]"
    >
      <img src={holdlogo} alt="web icon" />
    </NavLink>
  )
}
