import { InterfacePageName } from '@uniswap/analytics-events'
import { ChainId, Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { Trace } from 'analytics'
import berachain from 'assets/png/berachain.png'
import zkSync from 'assets/svg/zkSync.svg'
import { Hero } from 'components/Hero'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import { DefiApp, Protocol } from 'components/additional'
import { SwapTab } from 'components/swap/constants'
import { SwapWrapper } from 'components/swap/styled'
import SwapHeader from 'components/swap/swap-header/SwapHeader'
import { DEFAULT_CHAIN_ID, asSupportedChain } from 'constants/chains'
import { useCurrency } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useScreenSize } from 'hooks/useScreenSize'
import { SendForm } from 'pages/Swap/Send/SendForm'
import { ReactNode, useMemo } from 'react'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { isPreviewTrade } from 'state/routing/utils'
import { SwapAndLimitContextProvider, SwapContextProvider, SwapWrapperV2 } from 'state/swap/SwapContext'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { CurrencyState, SwapAndLimitContext } from 'state/swap/types'
import { useIsDarkMode } from '../../theme/components/ThemeToggle'
import { Chart } from './Chart/Chart'
import { LimitFormWrapper } from './Limit/LimitForm'
import { SwapForm } from './SwapForm'

export function getIsReviewableQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  if (swapInputError) return false
  // if the current quote is a preview quote, allow the user to progress to the Swap review screen
  if (isPreviewTrade(trade)) return true

  return Boolean(trade && tradeState === TradeState.VALID)
}

export default function SwapPage({ className }: { className?: string }) {
  const { chainId: connectedChainId } = useWeb3React()
  const supportedChainId = asSupportedChain(connectedChainId)
  const chainId = supportedChainId || DEFAULT_CHAIN_ID

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)

  // DEFAULT_TOKEN_OUT USDT
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <Hero />

      <Swap
        className={className}
        chainId={chainId}
        disableTokenInputs={supportedChainId === undefined}
        initialInputCurrency={initialInputCurrency}
        initialOutputCurrency={initialOutputCurrency}
        syncTabToUrl={true}
      />

      <NetworkAlert />

      <div>
        <DefiApp />

        <Protocol />
      </div>

      {/* {location.pathname === '/swap' && <SwitchLocaleLink />} */}
    </Trace>
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
export function Swap({
  className,
  initialInputCurrency,
  initialOutputCurrency,
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
  compact = false,
  syncTabToUrl,
}: {
  className?: string
  chainId?: ChainId
  onCurrencyChange?: (selected: CurrencyState) => void
  disableTokenInputs?: boolean
  initialInputCurrency?: Currency
  initialOutputCurrency?: Currency
  compact?: boolean
  syncTabToUrl: boolean
}) {
  const isDark = useIsDarkMode()
  const screenSize = useScreenSize()

  return (
    <SwapAndLimitContextProvider
      chainId={chainId}
      initialInputCurrency={initialInputCurrency}
      initialOutputCurrency={initialOutputCurrency}
    >
      {/* TODO: Move SwapContextProvider inside Swap tab ONLY after SwapHeader removes references to trade / autoSlippage */}
      <SwapWrapperV2>
        <SwapAndLimitContext.Consumer>
          {({ currentTab }) => (
            <SwapContextProvider>
              <div className="flex flex-col-reverse lg:flex-row w-full items-center justify-center px-[8px] md:px-[40px] lg:min-h-[calc(100vh-72px)]">
                <Chart />

                <div className="flex flex-col items-center w-full lg:w-auto">
                  <SwapWrapper isDark={isDark} className={className} id="swap-page">
                    <SwapHeader compact={compact || !screenSize.sm} syncTabToUrl={syncTabToUrl} />
                    {currentTab === SwapTab.Swap && (
                      <SwapForm onCurrencyChange={onCurrencyChange} disableTokenInputs={disableTokenInputs} />
                    )}
                    {currentTab === SwapTab.Limit && <LimitFormWrapper onCurrencyChange={onCurrencyChange} />}
                    {currentTab === SwapTab.Send && (
                      <SendForm disableTokenInputs={disableTokenInputs} onCurrencyChange={onCurrencyChange} />
                    )}
                  </SwapWrapper>

                  <div className="relative py-5 flex items-center justify-center gap-2.5">
                    {/* <img src={asset} alt="asset" className="absolute w-[500px] aspect-square" /> */}
                    <img
                      src={berachain}
                      alt="berachain"
                      width={239}
                      height={40}
                      loading="lazy"
                      className="relative w-[107px]"
                    />
                    <img
                      src={zkSync}
                      alt="zkSync"
                      width={103}
                      height={20}
                      loading="lazy"
                      className="relative w-[85px]"
                    />
                  </div>
                </div>
              </div>
            </SwapContextProvider>
          )}
        </SwapAndLimitContext.Consumer>
      </SwapWrapperV2>
    </SwapAndLimitContextProvider>
  )
}
