import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import clsx from 'clsx'
import { ProtocolResponse } from 'components/swap/SwapRouting/type'
import { useContext, useEffect, useMemo } from 'react'
import { SwapProvider } from 'state/swap/SwapContext'
import { useSwapContext } from 'state/swap/hooks'
import { ChartInput } from './ChartInput'

export function Chart() {
  const {
    state: { chart },
    setState,
  } = useContext(SwapProvider)

  const {
    derivedSwapInfo: {
      trade: { trade },
    },
  } = useSwapContext()

  const { chainId } = useWeb3React()

  const address = useMemo(() => {
    if (!chainId || ![ChainId.ZKSYNC, ChainId.BERACHAIN].includes(chainId)) {
      return ''
    }

    const newTrade: ProtocolResponse = trade as any

    if (!newTrade?.routes) {
      return ''
    }

    if (newTrade.routes.length > 1) {
      return ''
    }

    if (newTrade.routes[0].swaps.length > 1) {
      return ''
    }

    return newTrade.routes[0].swaps[0].pool
  }, [chainId, trade])

  // only trigger when change router pool address
  useEffect(() => {
    setState((v) => {
      return {
        ...v,
        chart: !!address,
      }
    })
  }, [address, setState])

  return (
    <div
      className={clsx('relative self-stretch flex-1 flex flex-col justify-center gap-[24px] transition-all lg:pr-10', {
        'flex-none w-0 opacity-0 !pr-0': !chart,
      })}
    >
      <ChartInput address={address} />
    </div>
  )
}
