import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'

export function WebView({ address }: { address: string }) {
  const { chainId } = useWeb3React()

  return (
    <div className="flex-1 flex flex-col justify-center">
      <iframe
        src={`https://dexscreener.com/${
          chainId === ChainId.BERACHAIN ? 'berachain' : 'zksync'
        }/${address}?embed=1&theme=dark&trades=0&info=0`}
        width="100%"
        height={469}
      />
    </div>
  )
}
