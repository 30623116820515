import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { formatDisplay } from 'utils/formatNumbers'
import { useAlltime, useFees, useSwapper } from './data'

export function Protocol() {
  const { data } = useAlltime()
  const { data: fees } = useFees()
  const { data: swappers } = useSwapper()

  const formatted = useMemo(() => {
    if (!data) {
      return {
        all: '--',
        oneday: '--',
      }
    }

    const total = new BigNumber(data[0].total_vols).plus(data[1].total_vols).toNumber()
    const totalDay = new BigNumber(data[0].vols_24h).plus(data[1].vols_24h).toNumber()

    return {
      all: formatDisplay(total, { decimalToShow: 2 }),
      oneday: formatDisplay(totalDay, { decimalToShow: 2 }),
    }
  }, [data])

  const formattedFees = useMemo(() => {
    if (!fees) {
      return '--'
    }

    const total = new BigNumber(fees[0].data.factories[0].totalFeesUSD)
      .plus(fees[1].data.factories[0].totalFeesUSD)
      .toNumber()

    return formatDisplay(total, { decimalToShow: 2 })
  }, [fees])

  const number_of_swapper = useMemo(() => {
    if (!swappers) {
      return '--'
    }

    return formatDisplay(Number(swappers.data), { decimalToShow: 0 })
  }, [swappers])

  return (
    <section className="mx-4 xl:mx-[182px] flex flex-col">
      <div className="flex flex-col lg:flex-row items-start justify-between gap-6 lg:gap-[120px] mb-10">
        <div className="flex flex-col gap-4">
          <h3 className="text-[28px] lg:text-[48px] leading-[32px] lg:leading-[52px] font-medium text-white">
            Trusted by millions
          </h3>

          <p className="text-sm text-[#4C4C4C]">
            Hold.so already moves millions in volume, now it’s arrived on Berachain. Whether you swap, LP, or build, the
            protocol is where liquidity flows.
          </p>
        </div>
      </div>

      <div className="flex flex-col lg:mb-20">
        <div className="flex flex-col lg:flex-row lg:items-center">
          <div className="flex-1 p-6 border border-dashed border-[#141414] flex flex-col gap-2 hover:bg-[#0D0D0D] transition-all">
            <p className="text-white text-[32px] font-medium">{`$${formatted.all}`}</p>
            <p className="text-white text-base">All time volume</p>
          </div>

          <div className="flex-1 p-6 border border-dashed border-[#141414] flex flex-col gap-2 hover:bg-[#0D0D0D] transition-all">
            <div className="flex gap-2">
              <p className="text-white text-[32px] font-medium">{`$${formatted.oneday}`}</p>
            </div>

            <p className="text-white text-base">24 hour volume</p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row lg:items-center">
          <div className="flex-1 p-6 border border-dashed border-[#141414] flex flex-col gap-2 hover:bg-[#0D0D0D] transition-all">
            <p className="text-white text-[32px] font-medium">{`$${formattedFees}`}</p>
            <p className="text-white text-base">All time LP Fees</p>
          </div>

          <div className="flex-1 p-6 border border-dashed border-[#141414] flex flex-col gap-2 hover:bg-[#0D0D0D] transition-all">
            <p className="text-white text-[32px] font-medium">{number_of_swapper}</p>
            <p className="text-white text-base">All time swappers</p>
          </div>
        </div>
      </div>
    </section>
  )
}
