import clsx from 'clsx'
import { HoldstationIcon } from 'components/Logo/HoldstationIcon'
import Web3Status from 'components/Web3Status'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Z_INDEX } from 'theme/zIndex'
import { ChainSelector } from './ChainSelector'
import { Point } from './Point'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: ${Z_INDEX.sticky};
`

export const PageTabs = () => {
  const { pathname } = useLocation()

  return (
    <div className="flex items-center p-[2px]">
      <NavLink
        className={({ isActive }) => {
          return clsx(
            'text-md md:text-sm font-medium md:font-normal tracking-[-0.32px] px-[20px] py-[10px] md:rounded-full pb-[12px] md:border-none',
            {
              'text-white bg-black border-b border-white ': isActive,
              'text-[#808080] border-b border-black': !isActive,
            }
          )
        }}
        to="/swap"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        Swap
      </NavLink>

      <NavLink
        className={({ isActive }) => {
          return clsx(
            'text-md md:text-sm font-medium md:font-normal tracking-[-0.32px] px-[20px] py-[10px] md:rounded-full md:border-none',
            {
              'text-white bg-black border-b border-white':
                isActive || pathname.includes('/add') || pathname.includes('detail') || pathname.includes('remove'),
              'text-[#808080] border-b border-black': !isActive,
            }
          )
        }}
        to="/pool"
      >
        Pool
      </NavLink>

      <NavLink
        className={({ isActive }) => {
          return clsx(
            'text-md md:text-sm font-medium md:font-normal tracking-[-0.32px] px-[20px] py-[10px] md:rounded-full pb-[12px] md:border-none',
            {
              'text-white bg-black border-b border-white ': isActive,
              'text-[#808080] border-b border-black': !isActive,
            }
          )
        }}
        to="/bridge"
      >
        Bridge
      </NavLink>
    </div>
  )
}

const Navbar = () => {
  const isNftPage = useIsNftPage()

  return (
    <>
      {/* {blur && <Blur />} */}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className="flex md:flex-1 items-center">
            <Box className={styles.logoContainer}>
              <HoldstationIcon />
            </Box>

            {!isNftPage && (
              <Box display={{ sm: 'flex', lg: 'none' }}>
                <ChainSelector leftAlign={true} />
              </Box>
            )}

            <div className="hidden md:block absolute left-1/2 -translate-x-1/2 bg-[#171717] rounded-full">
              <PageTabs />
            </div>
          </Box>

          <Box className="flex flex-1 items-center justify-end">
            <Point />

            <Row gap="12">
              {/*{isNftPage && sellPageState !== ProfilePageStateType.LISTING && <Bag />}*/}
              {!isNftPage && (
                <Box display={{ sm: 'none', lg: 'flex' }}>
                  <ChainSelector />
                </Box>
              )}
              {/*{isLandingPage && <GetTheAppButton />}*/}
              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar
